<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">


      <v-card class="auth-card">

        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="brand-logo d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="230px"
              max-width="230px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

          </router-link>
        </v-card-title>
        <v-row class="auth-row ma-0">

          <v-col
            lg="12"
            class="d-flex align-center auth-bg pb-0"
          >
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="6"
                lg="12"
                class="mx-auto"
              >
                <v-card flat>
                  <v-card-text>
                    <p class="text-2xl font-weight-semibold text--primary mb-2">
                      Ein neues Passwort vergeben 🔒
                    </p>
                    <p class="mb-2">
                      Geben Sie ein neues Passwort ein.
                    </p>
                  </v-card-text>

                  <!-- login form -->
                  <v-card-text>

                    <div class="text-center" v-if="loading">
                      <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <v-form v-else
                            ref="form"
                            @submit.prevent="handleFormSubmit"
                    >
                      <v-text-field
                        type="password"
                        v-model="passwort"
                        outlined
                        label="Passwort"
                        placeholder="Passwort"
                        :error="(errorMessages.passwort != undefined)"
                        :error-messages="errorMessages.passwort"
                        :rules="[validators.required]"
                        hide-details="auto"
                        class="mb-4"
                      ></v-text-field>

                      <v-text-field
                        type="password"
                        v-model="passwort_w"
                        outlined
                        label="Passwort-Wiederholung"
                        placeholder="Passwort-Wiederholung"
                        :error="(errorMessages.passwort_w != undefined)"
                        :error-messages="errorMessages.passwort_w"
                        :rules="[validators.required]"
                        hide-details="auto"
                        class="mb-4"
                      ></v-text-field>

                      <v-btn
                        block
                        color="primary"
                        type="submit"
                      >
                        Absenden
                      </v-btn>
                    </v-form>
                  </v-card-text>

                  <v-card-actions class="d-flex justify-center align-center">
                    <router-link
                      :to="{name:'auth-login'}"
                      class="d-flex align-center text-sm"
                    >
                      <v-icon
                        size="24"
                        color="primary"
                      >
                        {{ icons.mdiChevronLeft }}
                      </v-icon>
                      <span>Zurück zum Login</span>
                    </router-link>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>



    </div>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheckOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import axios from "@axios";
import router from "@/router";
import {emailValidator, required} from '@core/utils/validation'
import {getCurrentInstance} from "@vue/composition-api/dist/vue-composition-api";


export default {
  setup() {
    const errorMessages = ref([])
    const form = ref(null)
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const tan = ref(router.currentRoute.params.tan)
    const hashKey = ref(router.currentRoute.params.hashKey)
    const idPtKunde = ref(router.currentRoute.params.idPtKunde)
    const passwort = ref('')
    const passwort_w = ref('')
    const loading = ref(false)

    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return
      loading.value = true;
      axios
        .post('/api/passwort_vergessen/', {
          userData: {
            tan: tan.value,
            hashKey: hashKey.value,
            idPtKunde: idPtKunde.value,
            passwort: passwort.value,
            passwort_w: passwort_w.value
          },
          aktion: 'passwordNewCheck'
        })
        .then(response => {
          if(response.data.valid === true){
            router.push({path:'/login'},()=>{
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            });
          }
          else{
            errorMessages.value = response.data.errors;
          }
          loading.value = false;
        })
        .catch(error => {
          errorMessages.value = error.response.data.errors
          loading.value = false;
        })
    }

    return {
      handleFormSubmit,
      tan,
      passwort,
      passwort_w,
      hashKey,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiCheckOutline
      },
      validators: {
        required,
      },
      form,
      errorMessages,
      loading

    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
